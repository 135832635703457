import React, { createContext, useState } from 'react';

export const InitPageStateData = {
  hasSideBar: false,
  sideBarExpanded: false
};

export const PageContext = createContext({
  state: InitPageStateData,
  dispatch: () => null
});

export const PageProvider = ({ children }) => {
  const [pageState, dispatchPage] = useState(InitPageStateData);

  return <PageContext.Provider value={[ pageState, dispatchPage ]}>
    { children }
  </PageContext.Provider>;
};
