import React from 'react';
import { Col, Container, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import Header from "../layout/Header";
import Footer from "../layout/Footer";

const Loading = () => {
  return <>
    <Header />
    <div className="h-50">
      <Container className="h-100">
        <Row className="h-100 align-items-center">
          <Col className="pt-5 pb-5 text-center">
            <FontAwesomeIcon icon={faSpinner} className="fa-spin" />&nbsp;
            Loading...
          </Col>
        </Row>
      </Container>
    </div>
    <Footer />
  </>;
};

export default Loading;
