import React, { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { ThemeProvider } from 'react-bootstrap';
import { PageContext } from '../contexts/Page'
import Footer from '../components/layout/Footer';
import '../scss/layout.scss';

import styles from './style.module.scss';

const Layout = () => {
  const [pageState] = useContext(PageContext);

  return (
    <ThemeProvider
      breakpoints={['xxl', 'xl', 'lg', 'md', 'sm', 'xs']}
      minBreakpoint="xs"
    >
      <main className={styles.layout}>
        <Outlet />
      </main>
      {!pageState.hasSideBar ? <Footer /> : ''}
    </ThemeProvider>
  )
};

export default Layout;
