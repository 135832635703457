import React, { lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PageProvider } from './contexts/Page';

import Layout from './pages/Layout';
import Loading from './components/Loading';
import Header from './components/layout/Header';

const Home = lazy(() => import('./pages/Home'));

const Setup = lazy(() => import('./pages/Setup'));
const SSHKeySetup = lazy(() =>  import('./pages/Setup/SSHKeySetup'));
const ProjectSetup = lazy(() =>  import('./pages/Setup/ProjectSetup'));
const DeploymentSetup = lazy(() =>  import('./pages/Setup/DeploymentSetup'));
const EnvironmentSetup = lazy(() =>  import('./pages/Setup/EnvironmentSetup'));

const Standards = lazy(() => import('./pages/Standards'));
const CodingStandards = lazy(() => import('./pages/Standards/Coding'));
const ProjectStandards = lazy(() => import('./pages/Standards/Project'));
const LocalDevelopmentStandards = lazy(() => import('./pages/Standards/LocalDevelopment'));
const LocalDevelopmentStandardsDocker = lazy(() => import('./pages/Standards/LocalDevelopment/Docker'));
const LocalDevelopmentStandardsGulp = lazy(() => import('./pages/Standards/LocalDevelopment/Gulp'));
const LocalDevelopmentStandardsReact = lazy(() => import('./pages/Standards/LocalDevelopment/React'));
const LocalDevelopmentStandardsVue = lazy(() => import('./pages/Standards/LocalDevelopment/VueJs'));
const WebsiteStandards = lazy(() => import('./pages/Standards/Website'));
const NamingConventionStandards = lazy(() => import('./pages/Standards/NamingConvention'));
const SecurityStandards = lazy(() => import('./pages/Standards/Security'));

const Documentation = lazy(() => import('./pages/Documentation'));
const Readme = lazy(() => import('./pages/Documentation/Readme'));
const GitLabWorkflow = lazy(() => import('./pages/Documentation/GitLabWorkflow'));
const GitLabWorkflowBranches = lazy(() => import('./pages/Documentation/GitLabWorkflow/Branches'));
const GitLabWorkflowBoards = lazy(() => import('./pages/Documentation/GitLabWorkflow/Boards'));
const GitLabWorkflowLabels = lazy(() => import('./pages/Documentation/GitLabWorkflow/Labels'));
const GitLabWorkflowIssues = lazy(() => import('./pages/Documentation/GitLabWorkflow/Issues'));
const GitLabWorkflowAutoDeployments = lazy(() => import('./pages/Documentation/GitLabWorkflow/AutoDeployments'));
const MergeRequests = lazy(() => import('./pages/Documentation/MergeRequests'));
const WebProxyService = lazy(() => import('./pages/Documentation/WebProxyService'));
const Tracking = lazy(() => import('./pages/Documentation/Tracking'));
const TrackingAdobe = lazy(() => import('./pages/Documentation/Tracking/Adobe'));
const TrackingGoogle = lazy(() => import('./pages/Documentation/Tracking/Google'));

const Documents = lazy(() => import('./pages/Documents'));
const PointsOfContact = lazy(() => import('./pages/PointsOfContact'));
const Resources = lazy(() => import('./pages/Resources'));

const NoPage = lazy(() => import('./pages/NoPage'));

function App() {
  return <PageProvider>
    <BrowserRouter basename={process.env.REACT_APP_BASENAME}>
      <React.Suspense fallback={<Loading />}>
        <Header />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />

            <Route path="/setup" element={<Setup />} />
            <Route path="/setup/ssh-keys" element={<SSHKeySetup />} />
            <Route path="/setup/projects" element={<ProjectSetup />} />
            <Route path="/setup/deployments" element={<DeploymentSetup />} />
            <Route path="/setup/environments" element={<EnvironmentSetup />} />

            <Route path="/standards" element={<Standards />} />
            <Route path="/standards/coding" element={<CodingStandards />} />
            <Route path="/standards/projects" element={<ProjectStandards />} />
            <Route path="/standards/local-development" element={<LocalDevelopmentStandards />} />
            <Route path="/standards/local-development/docker" element={<LocalDevelopmentStandardsDocker />} />
            <Route path="/standards/local-development/gulp" element={<LocalDevelopmentStandardsGulp />} />
            <Route path="/standards/local-development/react" element={<LocalDevelopmentStandardsReact />} />
            <Route path="/standards/local-development/vue" element={<LocalDevelopmentStandardsVue />} />
            <Route path="/standards/website" element={<WebsiteStandards />} />
            <Route path="/standards/naming-conventions" element={<NamingConventionStandards />} />
            <Route path="/standards/security" element={<SecurityStandards />} />

            <Route path="/documentation" element={<Documentation />} />
            <Route path="/documentation/readme" element={<Readme />} />
            <Route path="/documentation/gitlab-workflow" element={<GitLabWorkflow />} />
            <Route path="/documentation/gitlab-workflow/branches" element={<GitLabWorkflowBranches />} />
            <Route path="/documentation/gitlab-workflow/boards" element={<GitLabWorkflowBoards />} />
            <Route path="/documentation/gitlab-workflow/labels" element={<GitLabWorkflowLabels />} />
            <Route path="/documentation/gitlab-workflow/issues" element={<GitLabWorkflowIssues />} />
            <Route path="/documentation/gitlab-workflow/auto-deployments" element={<GitLabWorkflowAutoDeployments />} />
            <Route path="/documentation/merge-requests" element={<MergeRequests />} />
            <Route path="/documentation/web-proxy-service" element={<WebProxyService />} />
            <Route path="/documentation/tracking" element={<Tracking />} />
            <Route path="/documentation/tracking/adobe" element={<TrackingAdobe />} />
            <Route path="/documentation/tracking/google" element={<TrackingGoogle />} />

            <Route path="/documents" element={<Documents />} />

            <Route path="/resources" element={<Resources />} />

            <Route path="/points-of-contact" element={<PointsOfContact />} />

            <Route path="*" element={<NoPage />} />
          </Route>
        </Routes>
      </React.Suspense>
    </BrowserRouter>
  </PageProvider>;
}

export default App;
