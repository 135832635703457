import React from 'react';

const Logo = () => {
  return <svg
    xmlns="http://www.w3.org/2000/svg"
    width="74.631"
    height="50"
    viewBox="0 0 74.631 50"
    data-svg-inline=""
    role="presentation"
    focusable="false"
    tabIndex="-1"
  >
    <path
      d="M58.57 18.946s-5.478-4.582-8.765-5.416-10.3-1.754-12.271-1.085-2.191 1.294-4.164 1.294-10.081.833-10.957.208-6.574-2.84-9.861-1.732a24.752 24.752 0 00-8.765 5.065c-2.191 2.083-4.165 6.457-3.726 10a70.711 70.711 0 002.192 9.37c.438 1.666-1.972 1.875-1.1 3.332s3.61 6.116 3.219 7.272c-.358 1.053-1.365 2.688.507 2.727l6.216.021c1.13.022.611-.973.611-.973s.2.007.376 0a3.408 3.408 0 00.746-.084s.633-.976-.682-1.6-2.666-6.114-2.447-7.988c0 0 4.821-2.083 5.26-4.371 0 0 6.354 3.33 12.271 3.33s8.765-1.042 8.765-1.042l.219 1.667s-1.048 3.456-.438 5.623c.873 3.1-1.252 3.884-.6 5.21a10.538 10.538 0 002.606.227h4.621c1.354-.162.67-.955.67-.955s.517.034.973.034c1.13 0 1.251-.134 1.251-.134.317-1.429-.777-1.258-1.53-1.858s-.324-4.815-.1-6.48 1.533-5.2 3.726-6.246 4.162-1.666 5.259-1.458a19.471 19.471 0 002.849.209 9.415 9.415 0 013.068 1.666c1.1 1.041 3.945 1.872 6.356 1.872s1.972.417 4.382.209l2.41-.209s3.506-.925 1.1-5.564c0 0 3.445-4.015.845-11.1 0 0-.188 6.457-5.009 8.957 0 0 .439-4.791-.219-5a25.479 25.479 0 00-2.63 2.916s-.22-2.082-1.095-2.5a1.517 1.517 0 010-2.5 5.826 5.826 0 001.753-1.874l-3.725 1.041s-.219-3.332-.876-3.749a4.038 4.038 0 00-1.972-.833c-.658 0-1.316 2.5-1.316 2.5"
      data-name="Path 52"
      fill="#27a2c7"
    ></path>
    <path
      d="M35.222.459a2.655 2.655 0 012.356-.293c1.239.5 2.066 2.275 2.314 2.689s.909.661 1.033.868a7.355 7.355 0 011.653 2.438l.949 2.314v.207l-.372-.248.372.909 3.224 1.61v.166l-.827-.247-.33.247-.538-.207-.33.207a9.865 9.865 0 00-2.107-.5 12.539 12.539 0 00-1.818.5 6.272 6.272 0 00-.661.95c0 .07.1.186.257.238.073.023.165-.051.23-.041.191.029.182.254.182.254a.635.635 0 00-.1-.052c-.055-.014-.095.045-.142.053a1.283 1.283 0 00-.446.077c-.239.084-.443.308-.845.858-.1.137-.119 0-.28.289-.026.047-.067-.379.075-.42.217-.062.385-.624.385-.624a7.19 7.19 0 00-.62.651c-.175.219-.185-.027-.362.309-.038.072-.066-.364.149-.466a5.64 5.64 0 00.5-.5 3.056 3.056 0 01-.647.384c-.134 0-.253.168-.253.168a.421.421 0 01.267-.438c.453-.158.647-.487.807-.55.468-.188.934-1.062.934-1.062l-.206-.123s-1.4.186-1.687-.062-.934 1.018-.976 1.143a.19.19 0 00.3.148c.171-.09.262.146.262.146s-.064-.039-.172.032-.211.024-.285.084c-.892.7-.746.939-.983.991-.2.043-.208.192-.208.192a.314.314 0 01.107-.381 1.358 1.358 0 00.371-.526c-.321.19-.514.615-.806.662-.258.043-.195.227-.22.149a.312.312 0 01.173-.368 2.364 2.364 0 00.53-.541c-.217.157-.4.416-.714.428-.077 0-.1.178-.1.178-.077-.124.05-.371.155-.41.488-.184.529-.458.84-.618a2.758 2.758 0 00.91-1.268 1.518 1.518 0 01-.427-.765s-1.24-2.322-.95-3.289a3.131 3.131 0 01-.248-3.157c.744-2.065-.661-2.292-.661-2.292a6.094 6.094 0 01-1.694-.846 1.629 1.629 0 011.694-.25"
      data-name="Path 53"
      fill="#db2885"
    ></path>
  </svg>;
};

export default Logo;
